import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import SubHeader from '../components/SubHeader'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { useAuth } from '../context/AuthContext';
import { errorInterceptor } from '../core/errorInterceptor';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColorRing } from 'react-loader-spinner';
import Footer from '../components/Footer';

function MyReports(props) {

  const auth = useAuth();

  const [isLoading, setIsLoading] = useState(true);
  const [kidsPicUrl] = useState(`https://api.kidsalert.co.zw/kids/download/file/path/`);
  const [defaultPicUrl] = useState(`${require('../assets/placeholder-image.png')}`);
  const [kids, setKids] = useState([])

  const getMyReports = () => {
    auth.getRequest(`/kids/active/`)
      .then(response => {
        setKids(response.items)
        setIsLoading(false)
      })
      .catch(error => {
        errorInterceptor(error);
        setIsLoading(false)
      })
  }

  useEffect(() => {
    getMyReports();
    // eslint-disable-next-line
  },)

  const calculateAge = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    // if age is less than 1 but greater than 0 months return months
    if (age < 1 && (today.getMonth() - birthDate.getMonth()) > 0) {
      return `${today.getMonth() - birthDate.getMonth()} months`
    }

    // if age is less than 1 month return days
    if (age < 1 && (today.getMonth() - birthDate.getMonth()) < 1) {
      return `${today.getDate() - birthDate.getDate()} days`
    }

    // if age is equal to 1 return year else return years
    if (age === 1) {
      return age + " year";
    }

    return age + " years";
  }

  return (
    <>
      <Header link={props.link} />
      <main style={{ marginBottom: 50}}>
        <SubHeader title={'My Reports'} description={'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'} />
        <Container>
          {isLoading &&
            <div className="d-flex justify-content-center align-items-center" style={{ height: "30vh" }}><ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
            /></div>}
          {!isLoading &&
            <>{kids.length === 0 &&
              <>
                <div className="d-flex justify-content-center align-items-center" style={{ height: "30vh" }}>
                  <Card border="light" style={{ border: 'none' }}>
                    <Card.Body className="text-center">
                      <i className="bi bi-emoji-frown" style={{ fontSize: "10rem" }} />

                      <Card.Text className='description'>
                        No results found
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              </>
            }
              {kids.length > 0 &&
                <>
                  <Row style={{ marginTop: 50 }} className="justify-content-between">
                    {kids.map((kid, index) => (
                      <Col key={index} md={3} lg={3} >
                        <Link to={`/view/${kid.uuid}`} style={{ textDecoration: 'none' }} className='d-flex flex-column align-items-center'>
                          <Card className='kids-section-column' style={{ border: 'none' }}>
                            {kid.kid_attachments[0] && <img
                              variant="top"
                              alt="featured"
                              className='kid-image'
                              src={kidsPicUrl + kid.kid_attachments[0].attachment?.id}
                            />
                            }
                            {!kid.kid_attachments[0] && <img variant="top" alt="featured" className='kid-image' src={defaultPicUrl} />}
                            {kid.status === "MISSING" && <div className="missing-status-tag"></div>}
                            {kid.status === "FOUND" && <div className="found-status-tag"></div>}
                            {kid.status === "UNKNOWN" && <div className="unknown-status-tag"></div>}

                            <p className='kid-name text-gray'>{kid.first_name} {kid.last_name} <br /> <span className='small-text'>({calculateAge(kid.date_of_birth)})</span></p>
                            <p className='kid-address text-gray'>
                              <FontAwesomeIcon icon={`fa-solid ${kid.gender === 'MALE' ? 'fa-mars' : 'fa-venus'}`} style={{ marginRight: 10 }}
                                title={`${kid.gender}`}
                              />
                              {kid.location_last_seen}
                            </p>
                          </Card>

                        </Link>
                      </Col>
                    ))}
                  </Row>
                </>
              }
            </>
          }
        </Container>
      </main>
      <Footer />
    </>
  )
}

export default MyReports
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Home from './Home';
import NotFound from './NotFound';
import Help from './Help';
import News from './News';
import Support from './Support';
import Contact from './Contact';
import About from './About';
import 'bootstrap/dist/css/bootstrap.min.css';
import View from './View';
import ScrollToTop from '../components/ScrollToTop';
import React from 'react';
import Login from './Login';
import Register from './Register';
import ForgotPassword from "./ForgotPassword";
import ConfirmAccount from "./ConfirmAccount";
import ConfirmOtp from "./ConfirmOtp";
import ResetPassword from "./ResetPassword";
import UpdateProfile from "./UpdateProfile";
import ChangePassword from "./ChangePassword";
import MyReports from "./MyReports";
import PoliceStations from "./PoliceStations";
import Organisations from "./Organisations";
import PrivacyPolicy from "./PrivacyPolicy";
import NewReport from "./NewReport";
import TermsAndConditions from "./TermsAndConditions";

// Protected route component
const ProtectedRoute = ({ user, redirectPath = '/sign-in', children }) => {
  if (!user) {
    return <Navigate to={redirectPath} replace state={{ user }} />;
  }

  // console.log(user)
  // if (user && user.password_reset) {
  //   navigate('/confirm-account', {
  //     state: {
  //       email: user.email,
  //       phone_number: user.phone_number
  //     }
  //   })
  // }

  return children;

};



function Navigation() {

  const user = localStorage.getItem('@userInfo') ? JSON.parse(localStorage.getItem('@userInfo')) : null;
  const protectedRoutes = [
    { path: '/confirm-account', component: ConfirmAccount },
    { path: '/reset-password', component: ResetPassword },
    { path: '/update-profile', component: UpdateProfile },
    { path: '/change-password', component: ChangePassword },
    { path: 'submit-a-report', component: NewReport },
    { path: 'my-reports', component: MyReports },
  ];

  const routes = [
    { path: "/", component: Home, link: "/" },
    { path: "/help", component: Help, link: "/help" },
    { path: "/news", component: News, link: "/news" },
    { path: "/support", component: Support, link: "/support" },
    { path: "/about-us", component: About, link: "/about-us" },
    { path: "/contact-us", component: Contact, link: "/contact-us" },
    { path: "/sign-in", component: Login, link: "/sign-in" },
    { path: "/sign-up", component: Register, link: "/sign-up" },
    { path: "/forgot-password", component: ForgotPassword, link: "/forgot-password" },
    { path: "/confirm-otp", component: ConfirmOtp, link: "/confirm-otp" },
    { path: "/police-stations", component: PoliceStations, link: "/police-stations" },
    { path: "/organisations", component: Organisations, link: "/organisations" },
    { path: "/privacy-policy", component: PrivacyPolicy, link: "/privacy-policy" },
    { path: "/terms-and-conditions", component: TermsAndConditions, link: "/terms-and-conditions" },
    { path: "/view/:id", component: View, link: "/view" },
    { path: "*", component: NotFound },
  ];

  return (
    <>
      <Router>
        <ScrollToTop />
        <Routes>
          {routes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={<route.component link={route.link} />}
            />
          ))}
          {protectedRoutes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={<ProtectedRoute user={user} children={<route.component />} />}
            />
          ))}
          <Route path="/view/:id" element={<View link={'/view'} />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </>
  );
}

export default Navigation;

import React from 'react'
import Header from '../components/Header'
import SubHeader from '../components/SubHeader'
import { Container, Row } from 'react-bootstrap'
import Footer from '../components/Footer'

function PrivacyPolicy(props) {
    return (
        <>
            <Header link={props.link} />
            <main>
                <SubHeader title={'Privacy Policy'} description={'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'} />
                <Container>
                    <Row style={{ marginTop: 50 }}>
                        <p className='description'>We at [company name] are committed to protecting your privacy. This Privacy Policy outlines how we collect, use, and share your personal information when you use our Amber Alert mobile app (the "App").</p>
                    </Row>

                    <Row style={{ marginTop: 20 }}>
                        <h1 className='sub-title' style={{ marginBottom: 10 }}>Information We Collect</h1>
                        <ul>
                            <li className="description">Personal Information: We may collect your name, email address, phone number, and location information when you create an account with the App or submit a report.
                                Usage Information: We may collect information about how you use the App, such as the pages you visit and the actions you take.
                                How We Use Your Information
                            </li>
                            <li className="description">Personal Information: We use your personal information to create and manage your account, and to contact you in case of an emergency or to provide you with updates about the App. We may also use your personal information for research and analysis purposes, but only in an aggregated and anonymized form.
                                Usage Information: We use usage information to improve the App and to better understand how users interact with it.
                                How We Share Your Information
                            </li>
                        </ul>
                    </Row>
                    <Row style={{ marginTop: 20 }}>
                        <p className='description'>
                            We may share your personal information with law enforcement agencies or emergency responders in case of an emergency.
                            We may share your personal information with service providers who help us operate the App, such as cloud hosting providers and analytics tools. These service providers are bound by strict confidentiality and data protection obligations.
                            We may share your information with third parties if required by law or if we believe in good faith that such disclosure is necessary to protect our rights or the safety of others.
                        </p>
                    </Row>

                    <Row style={{ marginTop: 20 }}>
                        <h1 className='sub-title' style={{ marginBottom: 10 }}>Security Measures</h1>
                        <p className='description'>We take the security of your information seriously and have implemented technical and organizational measures to protect it from unauthorized access, use, and disclosure.</p>
                    </Row>

                    <Row style={{ marginTop: 20 }}>
                        <h1 className='sub-title' style={{ marginBottom: 10 }}>Updates to this Privacy Policy</h1>
                        <p className='description'>We may update this Privacy Policy from time to time to reflect changes in our practices or applicable laws. We will notify you of any material changes and obtain your consent if required by law.</p>
                    </Row>

                    <Row style={{ marginTop: 20, marginBottom:50 }}>
                        <h1 className='sub-title' style={{ marginBottom: 10 }}>Contact Us</h1>
                        <p className='description'>If you have any questions or concerns about this Privacy Policy, please contact us at [contact information].
                        </p>
                    </Row>
                </Container>
            </main>

            <Footer />
        </>
    )
}

export default PrivacyPolicy
import React, { useEffect, useState } from 'react'
import '../App.css'
import { Card, Col, Container, Row } from 'react-bootstrap';
import Header from '../components/Header';
import Footer from '../components/Footer';
import CustomButton from '../components/CustomButton';
import CustomInput from '../components/CustomInput';
import CustomLink from '../components/CustomLink';
import { Link } from 'react-router-dom';
import { ColorRing } from 'react-loader-spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import api from '../core/api';
import { errorInterceptor } from '../core/errorInterceptor';
import toastr from '../core/toast';
import { useAuth } from '../context/AuthContext';

export default function Home(props) {

    const auth = useAuth();
    const { control, watch } = useForm(
        {
            defaultValues: {
                search: '',
            }
        }
    );

    let search = watch('search');

    const [mode, setMode] = useState('ALL');
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [location, setLocation] = useState({})
    const [loaded, setLoaded] = useState(undefined)


    const [kidsPicUrl] = useState(`https://api.kidsalert.co.zw/kids/download/file/path/`);
    const [defaultPicUrl] = useState(`${require('../assets/placeholder-image.png')}`);
    const [kids, setKids] = useState([])

    // implement load more pagination
    const [page, setPage] = useState(1);
    const size = 50;

    useEffect(() => {
        const handleSuccess = (position) => {
            const { latitude, longitude } = position.coords;
            setLocation({ latitude, longitude });
        };

        const handleError = (error) => {
            toastr.error(error.message)
        };

        navigator.geolocation.getCurrentPosition(handleSuccess, handleError);
    });

    const getKidsByLocation = () => {
        auth.postRequest(`/kids/search/nearby/`, location)
            .then(response => {
                setKids(response)
                setLoaded(Array(response.length).fill(false))
                setIsLoading(false)
            })
            .catch(error => {
                errorInterceptor(error);
                setIsLoading(false)
            })
    }

    const getKidsByStatus = async (status) => {
        if (page === 1) {
            setIsLoading(true)
        } else {
            setIsLoadingMore(true)
        }

        return new Promise(async (resolve, reject) => {
            api.get(`/kids/byStatus/${status}?page=${page}&size=${size}`)
                .then(response => {
                    resolve(response)
                    // if page is greater than 1, append the new kids to the existing kids array
                    if (page > 1) {
                        setKids([...kids, ...response.data.items])
                        // set the loaded array for the new kids to false and the rest to true
                        setLoaded([...loaded, ...Array(response.data.items.length).fill(false)])
                    } else {
                        setKids(response.data.items)
                        // set the loaded array for the new kids to false and the rest to true
                        setLoaded(Array(response.data.items.length).fill(false))
                    }
                    setIsLoading(false);
                    setIsLoadingMore(false);
                })
                .catch(error => {
                    reject(error)
                    errorInterceptor(error)
                    setIsLoading(false)
                    setIsLoadingMore(false)
                })
        })
    }

    useEffect(() => {
        // get kids depending on mode
        if (mode === 'NEARBY') {
            getKidsByLocation()
        } else {
            getKidsByStatus(mode)
        }
        // eslint-disable-next-line
    }, [page])

    useEffect(() => {
        setIsLoading(true)
        if (search.length > 3) {
            // search kids by name
            handleSearch()
        } else {

            // whenever the mode changes, reest to page 1
            setPage(1)
            // reset the kids array
            setKids([])
            // get kids depending on mode
            if (mode === 'NEARBY') {
                getKidsByLocation()
            } else {
                getKidsByStatus(mode)
            }
        }
        // eslint-disable-next-line
    }, [mode])


    const loadMore = () => {
        setIsLoadingMore(true)
        setPage(page + 1);
    }

    // function to calculate age from date of birth string
    const calculateAge = (dob) => {
        const today = new Date();
        const birthDate = new Date(dob);
        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        // if age is less than 1 but greater than 0 months return months
        if (age < 1 && (today.getMonth() - birthDate.getMonth()) > 0) {
            return `${today.getMonth() - birthDate.getMonth()} months`
        }

        // if age is less than 1 month return days
        if (age < 1 && (today.getMonth() - birthDate.getMonth()) < 1) {
            return `${today.getDate() - birthDate.getDate()} days`
        }

        // if age is equal to 1 return year else return years
        if (age === 1) {
            return age + " year";
        }

        return age + " years";
    }

    // custom style to blur image only and leave text visible   
    const bgImage = {
        height: 500,
        objectFit: 'cover',
        // get image from assets folder
        backgroundImage: `url(${require('../assets/kids-alert-bg.png')})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',

    }

    const handleSearch = () => {
        if (search) {
            setIsLoading(true)
            auth.postRequest(`/kids/searchByName?query=${search}&status=${mode}`)
                .then(response => {
                    setKids(response)
                    setLoaded(Array(response.length).fill(false))
                    setIsLoading(false)
                })
                .catch(error => {
                    errorInterceptor(error);
                    setIsLoading(false)
                })
        }
    }

    useEffect(() => {
        // only start searching when we have more than 3 characters
        if (search.length > 3) {
            handleSearch()
        }
        if (search.length === 0) {
            getKidsByStatus(mode)
        }
    // eslint-disable-next-line
    }, [search])

    return (
        <>
            <Header link={props.link} />
            <main>
                <div className="jumbotron jumbotron-fluid bg-image" style={bgImage}>
                    <div className="container" style={{ paddingTop: 130 }}>
                        <div className='d-flex h-100'>
                            <div className='text-white'>
                                <h1 className='header-title'>Find your missing kids</h1>
                                <p className="description-white">Welcome to Kids Alert where communities team together to curb issues of all missing and vulnerable children below 16 years. By working together and keeping a watchful eye on our surroundings, we can identify potential risks and take steps to prevent harm from coming to our children. "We are Each other's Eyes"</p>

                                <div style={{ marginTop: 50 }}>
                                    <CustomLink className='primary-button-link' url='/submit-a-report' role='button'>
                                        Submit a Report
                                    </CustomLink>
                                    <CustomLink className='primary-button-transparent-link' url='#search' role='button'>
                                        Search for someone
                                    </CustomLink>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <Container className='container-fluid'>
                    <Row style={{ marginTop: 50, marginBottom: 20 }} id='search'>
                        <h1 className='title' style={{ marginBottom: 10 }}>Missing Kids</h1>
                        <p className='description'>Kids Alert is a powerful community-driven initiative that aims to promote child safety and prevent harm from coming to vulnerable children. By working together and remaining vigilant, we can create a safer community for all.</p>
                    </Row>
                    <Row className='align-items-center'>
                        <Col className='text-start'>
                            <CustomInput
                                control={control}
                                type='text'
                                name='search'
                                placeholder={'Type anything to search'}
                            />
                        </Col>
                        <Col>
                        </Col>
                    </Row>

                    <Row>
                        <div className="container" style={{ marginTop: 50 }}>
                            <ul className="custom-nav-pills">
                                <li className="custom-nav-item" onClick={() => setMode('ALL')}><font className={`custom-nav-link ${mode === 'ALL' && 'active'}`}>All</font></li>
                                <li className="custom-nav-item" onClick={() => setMode('MISSING')}><font className={`custom-nav-link ${mode === 'MISSING' && 'active'}`}>Missing</font></li>
                                <li className="custom-nav-item" onClick={() => setMode('FOUND')}><font className={`custom-nav-link ${mode === 'FOUND' && 'active'}`}>Found</font></li>
                                <li className="custom-nav-item" onClick={() => setMode('NEARBY')}><font className={`custom-nav-link ${mode === 'NEARBY' && 'active'}`}>Nearby</font></li>
                            </ul>
                        </div>

                    </Row>

                    {isLoading &&
                        <div className="d-flex justify-content-center align-items-center" style={{ height: "30vh" }}><ColorRing
                            visible={true}
                            height="80"
                            width="80"
                            ariaLabel="blocks-loading"
                            wrapperStyle={{}}
                            wrapperClass="blocks-wrapper"
                            colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                        /></div>}
                    {!isLoading && <>{kids.length === 0 &&
                        <>
                            <div className="d-flex justify-content-center align-items-center" style={{ height: "30vh" }}>
                                <Card border="light" style={{ border: 'none' }}>
                                    <Card.Body className="text-center">
                                        <i className="bi bi-emoji-frown" style={{ fontSize: "10rem" }} />

                                        <Card.Text className='description'>
                                            No results found
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </div>
                        </>}
                        {kids.length > 0 &&
                            <>
                                <Row style={{ marginTop: 50 }} className="justify-content-between">
                                    {kids.map((kid, index) => (
                                        <Col key={index} md={3} lg={3} >
                                            <Link to={`/view/${kid.uuid}`} style={{ textDecoration: 'none' }} className='d-flex flex-column align-items-center'>
                                                <Card className='kids-section-column' style={{ border: 'none' }}>
                                                    {kid.kid_attachments[0] && <img
                                                        variant="top"
                                                        alt="featured"
                                                        className='kid-image'
                                                        src={kidsPicUrl + kid.kid_attachments[0].attachment?.id}
                                                    />
                                                    }
                                                    {!kid.kid_attachments[0] && <img variant="top" alt="featured" className='kid-image' src={defaultPicUrl} />}
                                                    {kid.status === "MISSING" && <div className="missing-status-tag"></div>}
                                                    {kid.status === "FOUND" && <div className="found-status-tag"></div>}
                                                    {kid.status === "UNKNOWN" && <div className="unknown-status-tag"></div>}

                                                    <p className='kid-name text-gray'>{kid.first_name} {kid.last_name} <br /> <span className='small-text'>({calculateAge(kid.date_of_birth)})</span></p>
                                                    <p className='kid-address text-gray'>
                                                        <FontAwesomeIcon icon={`fa-solid ${kid.gender === 'MALE' ? 'fa-mars' : 'fa-venus'}`} style={{ marginRight: 10 }}
                                                            title={`${kid.gender}`}
                                                        />
                                                        {kid.location_last_seen}
                                                    </p>
                                                </Card>

                                            </Link>
                                        </Col>
                                    ))}
                                </Row>
                                {isLoadingMore && <div className="d-flex justify-content-center align-items-center" style={{ height: "10vh" }}>
                                    <ColorRing
                                        visible={true}
                                        height="50"
                                        width="50"
                                        ariaLabel="blocks-loading"
                                        wrapperStyle={{}}
                                        wrapperClass="blocks-wrapper"
                                        colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                                    /></div>}
                                <Row className='my-4'>
                                    <Col className='text-center text-white py-3'>
                                        {search.length === 0 && mode !== 'NEARBY' && <CustomButton className='primary-button' size="lg" type='button' onClick={loadMore}>
                                            Load More
                                        </CustomButton>}
                                    </Col>
                                </Row>
                            </>
                        }</>
                    }
                </Container>
            </main>
            <Footer />

        </>
    )
}

import React, { useState } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import CustomButton from '../components/CustomButton'
import Footer from '../components/Footer'
import Header from '../components/Header'
import SubHeader from '../components/SubHeader'

export default function Support(props) {


    const [donationPicUrl] = useState(`${require('../assets/kids-alert-donation.png')}`);

    return (
        <>
            <Header link={props.link} />
            <main>
                <SubHeader title={'Support'} description={'We understand that this can be a difficult and emotional time, and we\'re here to help. Our team of experts is dedicated to providing you with the support and assistance you need to report a missing child, find resources, or address any technical issues you may encounter while using our application or how you can support our mission.'} />
                <Container>
                    <Row style={{ marginTop: 50 }}>
                        <h1 className='sub-title' style={{ marginBottom: 10 }}>Find out about how you can make a big difference</h1>
                        <p className='description'>
                            Our application is designed to help communities team together to curb issues of all missing and vulnerable children. We believe that everyone can make a difference, no matter how big or small, and we're here to help you do just that.
                            <br /><br />
                            With our application, you can post information about missing children, view reports of missing children in your area, and even use our facial recognition feature to help identify missing children. By using our application, you're taking an active role in helping to locate missing children and reunite them with their families.
                            <br /><br />
                            But there are also other ways you can make a big difference. You can spread the word about our application to your friends and family, share our social media posts, and encourage others to get involved. You can also volunteer your time and resources to organizations that support missing children, or donate to causes that help reunite missing children with their families.
                            <br /><br />
                            Every action, no matter how small, can make a big difference in the lives of missing children and their families. We appreciate your support and are excited to have you on board as we work together to bring missing children home.
                        </p>
                    </Row>

                    <Row style={{ marginTop: 50 }}>
                        <h1 className='sub-title' style={{ marginBottom: 20 }}>Fundraising for Missing People</h1>
                        <Col>
                            <Card className='news-featured-section' style={{ height: '100%', width: '100%', border: 'none' }}>
                                <img variant="top" alt="kids-alert-featured" className='support-featured-image' src={donationPicUrl} />
                            </Card>
                        </Col>
                        <Col className='d-flex align-items-center' style={{ minHeight: '100%' }}>
                            <div>
                                <p className='description'>
                                    With our missing kids application, we are committed to helping communities team together to curb issues of all missing and vulnerable children. Your fundraising efforts can make a significant impact in the lives of these children and their families.
                                    <br /><br />
                                    There are many ways to fundraise for missing children. You can organize an event in your community, such as a benefit concert, a bake sale, or a walk-a-thon. You can also create an online fundraising campaign through social media or a crowdfunding platform. By involving your friends, family, and community, you can raise awareness and support for this important cause.
                                    <br /><br />
                                    All funds raised through your efforts will go directly towards supporting our application and its features, such as the facial recognition technology, which helps locate missing children and reunite them with their families. We also support organizations that provide assistance to families of missing children, such as counseling and legal aid.
                                    <br /><br />
                                    Your support can make a difference in the lives of missing children and their families. We appreciate your efforts and look forward to working together to bring missing children home.
                                </p>
                            </div>
                        </Col>
                    </Row>

                </Container>
                <div style={{ backgroundColor: '#28367C' }}>
                    <Container>
                        <Row style={{ marginTop: 50, paddingTop: 80, paddingBottom: 80 }}>
                            <h1 className='sub-title text-white' style={{ marginBottom: 10 }}>Be a lifeline, make a donation</h1>
                            <p className='description text-white'>
                                We are committed to helping communities team together to curb issues of all missing and vulnerable children. Your donation can make a significant impact in the lives of these children and their families.
                                <br /><br />
                                All funds donated go directly towards supporting our application and its features, such as the facial recognition technology, which helps locate missing children and reunite them with their families. We also support organizations that provide assistance to families of missing children, such as counseling and legal aid.
                                <br /><br />
                                Your support can make a difference in the lives of missing children and their families. With your help, we can continue to provide vital resources to support the search and recovery of missing children, as well as provide support and assistance to their families.
                                <br /><br />
                                Thank you for being a lifeline to these missing children and their families. Every donation helps to bring us closer to our goal of reuniting these children with their loved ones.
                            </p>
                            <Row className='my-2'>
                                <Col className='text-left text-white'>
                                    <CustomButton className='primary-button-white' size="lg">
                                        Donate Today
                                    </CustomButton>
                                </Col>
                            </Row>
                        </Row>
                    </Container>
                </div>
                <Container>


                    <Row style={{ marginTop: 50, marginBottom: 50, paddingTop: 50, paddingBottom: 50 }}>
                        <h1 className='sub-title' style={{ marginBottom: 10 }}>How to help with the search</h1>
                        <p className='description'>
                            Your help can make a significant impact in the lives of these children and their families. Our missing kids application relies on the support of the community to help locate and recover missing children. Here are some ways that you can help:
                            <ol>
                                <li>Spread the word: Share information about missing children on social media and with your local community. The more people that know about a missing child, the greater the chances are of them being found.</li>

                                <li>Volunteer: Volunteer with local search and rescue organizations, or with groups that assist in the search for missing children. You can also volunteer with our organization to help with outreach efforts and support services for families of missing children.</li>

                                <li>Provide tips and information: If you have any information that could be helpful in the search for a missing child, please contact the relevant authorities immediately. Your tips and information could be the key to locating a missing child and reuniting them with their family.</li>

                                <li>Stay vigilant: Be on the lookout for missing children in your community. Keep an eye out for any signs of suspicious behavior or activity that could be related to a missing child. If you suspect that you have seen a missing child, contact the relevant authorities immediately.</li>
                            </ol>
                            Thank you for your commitment to helping with the search for missing children. With your help, we can work together to bring these children home safely.
                        </p>
                        <Row className='my-2'>
                            <Col className='text-left text-white'>
                                <CustomButton className='primary-button' size="lg">
                                    Learn More
                                </CustomButton>
                            </Col>
                        </Row>
                    </Row>
                </Container>

            </main>
            <Footer />
        </>)
}

import React from 'react'
import { Col, Container, Row, Stack } from 'react-bootstrap'
import CustomButton from '../components/CustomButton'
import Footer from '../components/Footer'
import Header from '../components/Header'
import SubHeader from '../components/SubHeader'
import CustomLink from '../components/CustomLink'

export default function Contact(props) {
    return (
        <>
            <Header link={props.link} />
            <main>
                <SubHeader title={'Get in touch'} description={'We appreciate your interest and support in our cause, and we look forward to hearing from you! If you are seeking assistance with something specific, please provide more details so that we can provide you with the help that you require. You can also let us know if you have any questions or concerns that we can help with.'} />
                <Container>
                    <Row style={{ marginTop: 50 }}>
                        <h1 className='sub-title' style={{ marginBottom: 10 }}>Need help? Talk to us on 0776000000</h1>
                        <p className='description'>
                            It is important to have a way for concerned individuals to reach out and report any information they may have. If you have any information regarding a missing child, please contact us immediately. We will do everything we can to help you find the child and reunite them with their family.
                        </p>
                    </Row>

                    <Row style={{ marginTop: 50 }}>
                        <h1 className='sub-title' style={{ marginBottom: 10 }}>Free and confidential</h1>
                        <p className='description'>
                            If you have any information about a missing child, please don't hesitate to reach out to us. We understand the sensitive nature of this issue, and we assure you that all information shared with us will be kept confidential. Our team is here to help in any way we can, and we are dedicated to reuniting missing children with their families.
                        </p>
                    </Row>

                    <Row style={{ marginTop: 50 }}>
                        <Stack direction="horizontal" gap={5}>
                            <div className="contact-card d-flex align-items-center" style={{ minHeight: '200px' }}>
                                <div>
                                    <p className='contact-card-title'>Call</p>
                                    <p className='contact-card-description'>If you have any information about a missing child, please don't hesitate to reach out to us using the number below</p>
                                    <p className='contact-card-description'>0776000000</p>
                                </div>
                            </div>
                            <div className="contact-card-outline d-flex align-items-center">
                                <div>
                                    <p className='contact-card-title'>Text/Whatsapp</p>
                                    <p className='contact-card-description'>if you have any information about a missing child, please feel free to send us a text or WhatsApp message using the number below.</p>
                                    <p className='contact-card-description'>0776000000</p>
                                </div>
                            </div>
                            <div className="contact-card d-flex align-items-center">
                                <div>
                                    <p className='contact-card-title'>Email</p>
                                    <p className='contact-card-description'>
                                        If you have any questions or concerns, please feel free to send us an email using the address below.
                                    </p>
                                    <p className='contact-card-description'>help@kidsalert.co.zw</p>
                                </div>
                            </div>
                        </Stack>
                    </Row>
                </Container>
                <div style={{ backgroundColor: '#28367C' }}>
                    <Container>
                        <Row style={{ marginTop: 50, paddingTop: 80, paddingBottom: 80 }}>
                            <Col md={8} lg={8}>
                                <h1 className='sub-title text-white' style={{ marginBottom: 10 }}>Be a lifeline, make a donation</h1>
                                <p className='description text-white text-justify'>
                                    We are committed to helping communities team together to curb issues of all missing and vulnerable children. Your donation can make a significant impact in the lives of these children and their families.
                                    <br /><br />
                                    All funds donated go directly towards supporting our application and its features, such as the facial recognition technology, which helps locate missing children and reunite them with their families. We also support organizations that provide assistance to families of missing children, such as counseling and legal aid.
                                    <br /><br />
                                    Your support can make a difference in the lives of missing children and their families. With your help, we can continue to provide vital resources to support the search and recovery of missing children, as well as provide support and assistance to their families.
                                    <br /><br />
                                    Thank you for being a lifeline to these missing children and their families. Every donation helps to bring us closer to our goal of reuniting these children with their loved ones.
                                </p>
                            </Col>
                            <Col className="d-flex align-items-center">
                                <Row className='my-2'>
                                    <Col className='text-left text-white'>
                                        <CustomButton className='primary-button-white' size="lg">
                                            Donate Now
                                        </CustomButton>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Container style={{ marginTop: 100, marginBottom: 100 }}>
                    <Row>
                        <h1 className='sub-title' style={{ marginBottom: 10 }}>We are here to help</h1>
                        <p className='description'>Our caring and highly traineed staff and  volunteers willl discuss your options and try to get you the support you need. If you're worried about the safety of a missing person, we would
                            encourage you to contact the police as a first point of call. We provide 7 day week emotional support to the duration of a disappearance.</p>
                        <p className='description'>We have access to the Language Line interpration service to facilitate contact with non-English speakers.</p>
                        <p className="description">If you contact us via text or email between 11pm and 9am, we will respond as soon as we can once we are open.</p>
                        <p className="description">If you need help urgently, please dial 999.</p>
                    </Row>
                    <Row className='my-2' style={{ marginTop: 20 }}>
                        <Col className='text-left text-white'>
                            <CustomLink className='primary-button-link' url='/submit-a-report'>
                                Report a missing child
                            </CustomLink>
                            <CustomLink className='primary-button-link' url="/police-stations">
                                Police Stations
                            </CustomLink>
                        </Col>
                    </Row>
                </Container>

            </main >
            <Footer />
        </>
    )
}
